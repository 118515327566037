<template>
  <svg viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.0002 0.666748C17.495 0.666748 0.868652 17.2931 0.868652 37.7983C0.868652 58.3035 17.495 74.9298 38.0002 74.9298C58.5054 74.9298 75.1317 58.3035 75.1317 37.7983C75.1317 17.2931 58.5054 0.666748 38.0002 0.666748ZM38.0002 68.6307C20.976 68.6307 7.16775 54.8224 7.16775 37.7983C7.16775 20.7741 20.976 6.96585 38.0002 6.96585C55.0243 6.96585 68.8326 20.7741 68.8326 37.7983C68.8326 54.8224 55.0243 68.6307 38.0002 68.6307Z" fill="currentColor"/>
    <path d="M51.6013 37.2429L33.4996 24.0811C33.4006 24.0092 33.2835 23.9661 33.1614 23.9566C33.0393 23.9471 32.917 23.9716 32.808 24.0274C32.699 24.0832 32.6076 24.1681 32.5438 24.2726C32.4801 24.3772 32.4466 24.4974 32.447 24.6198V50.9434C32.4472 51.0656 32.4811 51.1854 32.5449 51.2896C32.6088 51.3938 32.7001 51.4784 32.8089 51.5341C32.9177 51.5898 33.0397 51.6144 33.1616 51.6052C33.2834 51.5961 33.4004 51.5535 33.4996 51.4822L51.6013 38.3121C51.6868 38.2516 51.7565 38.1715 51.8047 38.0785C51.8528 37.9854 51.878 37.8822 51.878 37.7775C51.878 37.6727 51.8528 37.5695 51.8047 37.4765C51.7565 37.3835 51.6868 37.3034 51.6013 37.2429Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlayCircle'
}
</script>
